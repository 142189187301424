let loadingElement = null;
// If document ready INIT
if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
    init();
} else {
  document.addEventListener("DOMContentLoaded", init);
}

function correctNames (name) {
    name = name.toUpperCase();
    if (name === '¡A VOS, JOSEFINA!') {
        return 'A VOS JOSEFINA';
    } else if (name === 'PORTADA') {
        return 'PROYECTO JOSEFINA';
    } else if (name === 'VICTORIA OCAMPO, MATÍAS ERRÁZURIZ Y ') {
        return 'VICTORIA OCAMPO, MATÍAS ERRÁZURIZ Y LE CORBUSIER, UN TRÍO DECEPCIONADO';
    } else if (name === 'LA FIESTA DE ') {
        return 'LA FIESTA DE LE CORBUSIER Y JOSEPHINE BAKER';
    }
    return name;
}

function findContainerElement (elem) {
    if (elem && elem.parentElement ) {
        if (elem.parentElement.classList.contains('header-section') || elem.parentElement.classList.contains('section')) {
            return elem.parentElement;
        } else {
            return findContainerElement(elem.parentElement);
        }
    } else {
        return null;
    }
}

function init() {
    // MENU
    let menuToggleElement = document.getElementById('menu-toggle');
    let menuElement = document.getElementById('menu');
    menuToggleElement.addEventListener('click', () => {
        if (menuElement.classList.contains('open')) {
            menuElement.classList.remove('open');
            document.body.classList.add('loaded');
        } else {
            menuElement.classList.add('open');
            document.body.classList.remove('loaded');
        }
    });
    let allMenus = document.querySelectorAll('h1');
    let _allMenuItems = document.querySelectorAll('.jumpToMenu');
    let allMenuItems = {};
    let _lastItem = null;
    _allMenuItems.forEach((elem) => {
        let _newItem = {
            elem
        };
        if (_lastItem) {
            _newItem.prev = _lastItem;
            _lastItem.next = _newItem;
        }
        allMenuItems[correctNames(elem.childNodes[0].nodeValue)] = _newItem;
        _lastItem = _newItem;
        elem.addEventListener('click', () => {
            if (allMenuItems[correctNames(elem.childNodes[0].nodeValue)].ref) {
                menuElement.classList.remove('open');
                document.body.classList.add('loaded');
                setTimeout(() => {
                    scrollToPos(allMenuItems[correctNames(elem.childNodes[0].nodeValue)].ref.offsetTop);
                });
            }
        })
    });
    allMenuItems['PROYECTO JOSEFINA'].prev = allMenuItems['FUENTES'];
    allMenuItems['FUENTES'].next = allMenuItems['PROYECTO JOSEFINA'];
    let lastActiveItem = null;
    let activeItem = null;
    allMenus.forEach((elem) => {
        if (elem.childNodes[0].nodeValue.includes('DOSSIER')) {
            return;
        }
        if (allMenuItems[correctNames(elem.childNodes[0].nodeValue)]) {
            if (correctNames(elem.childNodes[0].nodeValue) === 'PROYECTO JOSEFINA') {
                allMenuItems['PROYECTO JOSEFINA'].ref = {offsetTop: 0};
            } else {
                allMenuItems[correctNames(elem.childNodes[0].nodeValue)].ref = elem;
            }
        }
        suscribeElementScrollCheck(findContainerElement(elem), 0, () => {
            if (correctNames(elem.childNodes[0].nodeValue) !== activeItem) {
                lastActiveItem = activeItem;
                if (lastActiveItem && allMenuItems[lastActiveItem] && allMenuItems[lastActiveItem].elem && allMenuItems[lastActiveItem].elem.classList.contains('active')) {
                    allMenuItems[lastActiveItem].elem.classList.remove('active');
                }
                activeItem = correctNames(elem.childNodes[0].nodeValue);
                if (activeItem) {
                    allMenuItems[activeItem].elem.classList.add('active');
                }
            }
        }, null, true, true);
    });
    let siguienteDiv = document.getElementById('siguiente');
    siguienteDiv.addEventListener('click', () => {
        if (activeItem && allMenuItems[activeItem].next) {
            if (menuElement.classList.contains('open')) {
                menuElement.classList.remove('open');
                document.body.classList.add('loaded');
            }
            setTimeout(() => {
                scrollToPos(allMenuItems[activeItem].next.ref.offsetTop);
            });
        }
    });
    let anteriorDiv = document.getElementById('anterior');
    anteriorDiv.addEventListener('click', () => {
        if (activeItem && allMenuItems[activeItem].prev) {
            if (menuElement.classList.contains('open')) {
                menuElement.classList.remove('open');
                document.body.classList.add('loaded');
            }
            setTimeout(() => {
                scrollToPos(allMenuItems[activeItem].prev.ref.offsetTop);
            });
        }
    });
    let randomDiv = document.getElementById('random');
    let getRandom = () => {
        let rand = Math.floor(Math.random() * (allMenus.length - 3) + 2);
        if (allMenuItems[correctNames(allMenus[rand].childNodes[0].nodeValue)] !== activeItem) {
            return correctNames(allMenus[rand].childNodes[0].nodeValue);
        } else {
            return getRandom();
        }
    }
    randomDiv.addEventListener('click', () => {
        if (menuElement.classList.contains('open')) {
            menuElement.classList.remove('open');
            document.body.classList.add('loaded');
        }
        setTimeout(() => {
            scrollToPos(allMenuItems[getRandom()].ref.offsetTop);
        });
    });

    // Loading Element
    loadingElement = document.getElementById('loaded-percentage');
    loadingElement.innerText = 17;

    // Init effect
    let backgroundContainer = document.getElementById('gooey-effect');
    let logos = document.getElementById('logos');

    suscribeElementScrollCheck(backgroundContainer, 90, () => {
        if (!logos.classList.contains('initial-animation')) {
            logos.classList.add('initial-animation');
        }
    }, () => {
        if (logos.classList.contains('initial-animation')) {
            logos.classList.remove('initial-animation');
        }
    });

    // Overlay hide
    let videoWithOverlayElement = document.getElementById('cahen-video');
    let overlayElement = document.getElementById('overlay-cahen');
    let playOverlay = () => {
        overlayElement.style.display = 'none';
        videoWithOverlayElement.removeEventListener('playing', playOverlay);
        let cahenWasPlaying = true;
        videoWithOverlayElement.addEventListener('pause', () => {
            if (cahenWasPlaying !== 'pause_by_scroll') {
                cahenWasPlaying = 'pause_by_user';
            }
        });
        videoWithOverlayElement.addEventListener('play', () => {
            cahenWasPlaying = 'playing';
        });
        suscribeElementScrollCheck(document.getElementById('cahen-video-container'), 60, () => {
            if (cahenWasPlaying === 'pause_by_scroll') {
                videoWithOverlayElement.play()
            }
        }, () => {
            if (cahenWasPlaying === 'playing') {
                cahenWasPlaying = 'pause_by_scroll';
                videoWithOverlayElement.pause()
            }
        });
    }
    videoWithOverlayElement.addEventListener('playing', playOverlay);
    // Overlay hide 2
    let videoWithOverlayElement2 = document.getElementById('tejedor-video');
    let overlayElement2 = document.getElementById('overlay-tejedor');
    let playOverlay2 = () => {
        overlayElement2.style.display = 'none';
        videoWithOverlayElement2.removeEventListener('playing', playOverlay2);
        let tejedorWasPlaying = 'playing';
        videoWithOverlayElement2.addEventListener('pause', () => {
            if (tejedorWasPlaying !== 'pause_by_scroll') {
                tejedorWasPlaying = 'pause_by_user';
            }
        });
        videoWithOverlayElement2.addEventListener('play', () => {
            tejedorWasPlaying = 'playing';
        });
        suscribeElementScrollCheck(document.getElementById('tejedor-video-container'), 60, () => {
            if (tejedorWasPlaying === 'pause_by_scroll') {
                videoWithOverlayElement2.play()
            }
        }, () => {
            if (tejedorWasPlaying === 'playing') {
                tejedorWasPlaying = 'pause_by_scroll';
                videoWithOverlayElement2.pause()
            }
        }); 
    }
    videoWithOverlayElement2.addEventListener('playing', playOverlay2);
    // Suscribe video to play when in viewPort
    let videoElement = document.getElementById('valansi-video');
    suscribeElementScrollCheck(videoElement, 60, () => {
        videoElement.play();
    }, () => {
        videoElement.pause()
    });
    // Suscribe video to play when in viewPort
    let videoElement3 = document.getElementById('maciel-video');
    suscribeElementScrollCheck(videoElement3, 60, () => {
        videoElement3.play();
    }, () => {
        videoElement3.pause()
    });
    // Suscribe dinamic images when in viewPort
    let moveImagePosition = (element, visiblePercentage) => {
        visiblePercentage = Math.floor(visiblePercentage * 100);
        let scrollPosition = window.pageYOffset - element.offsetTop + window.innerHeight;
        if (scrollPosition > 0 && scrollPosition < window.innerHeight * 1.8) {
            element.style.objectPosition = `center ${Math.floor(scrollPosition / (window.innerHeight * 1.8) * 100)}%`;
        }
    }
    let dinamicImagesElements = document.querySelectorAll('.image-dinamic');
    dinamicImagesElements.forEach((imageElement) => {
        suscribeElementScrollCheck(imageElement, 1, moveImagePosition, moveImagePosition, true);
    });
    let allImagesLoad = () => {
        onLoadGooey();
        // Init carousel
        let carouselElements = document.getElementsByClassName( 'splide-effect' );
        for (let element of carouselElements) {
            new Splide(element, {
                type   : 'loop',
                autoplay: true,
                arrows: false,
                interval: Math.floor(Math.random() * 1900) + 1200,
                pauseOnHover: false
            }).mount();
        };
        let carouselElements2 = document.getElementsByClassName( 'splide-normal' );
        for (let element2 of carouselElements2) {
            new Splide(element2, {
                type   : 'loop',
                autoplay: true,
                arrows: true,
                interval: 4000
            }).mount();
        };
        // Init background effect
        let index = 1;
        let backgroundElement = document.getElementById('first-layer');
        let backgroundElementSecondLayer = document.getElementById('second-layer');
        let newUrl = '../res/images/slider_josefina_2.jpg';
        let lastTimeStamp = 0;
        let backgroundAction = (event) => {
            if (event.timeStamp - lastTimeStamp > 1000) {
                backgroundElement.classList.remove('transition');
                backgroundElement.style.backgroundImage = `url("${newUrl}")`;
                if (timeOut !== 'suspended') {
                    timeOut = setTimeout(changeBackground, 2000);
                }
                lastTimeStamp = event.timeStamp;
            }
        };
        backgroundElement.addEventListener('transitionend', backgroundAction);
        backgroundElement.addEventListener('transitioncancel', backgroundAction);
        let timeOut = null;
        let changeBackground = () => {
            index = index++ > 3 ? 1 : index;
            newUrl = `../res/images/slider_josefina_${index}.jpg`;
            backgroundElementSecondLayer.style.backgroundImage = `url("${newUrl}")`;
            backgroundElement.classList.add('transition');
        }
        suscribeElementScrollCheck(backgroundContainer, 1, () => {
            if (!timeOut || timeOut === 'suspended') {
                timeOut = null;
                changeBackground();
                backgroundElement.addEventListener('transitionend', backgroundAction);
                backgroundElement.addEventListener('transitioncancel', backgroundAction);
            }
        }, () => {
            if (timeOut) {
                backgroundElement.style.backgroundImage = backgroundElementSecondLayer.style.backgroundImage;
                backgroundElement.removeEventListener('transitionend', backgroundAction);
                backgroundElement.removeEventListener('transitioncancel', backgroundAction);
                backgroundElement.classList.remove('transition');
                window.clearTimeout(timeOut);
                timeOut = 'suspended';
            }
        });
        // Start
        let loadingSection = document.getElementById('loading-section');
        loadingElement.innerText = 100;
        setTimeout(() => {
            let startApp = () => {
                loadingSection.removeEventListener('transitionend', startApp);
                loadingSection.removeEventListener('transitioncancel', startApp);
                document.body.classList.add('loaded');
                setTimeout(() => {
                    loadingSection.style.display = 'none';
                });
            }
            loadingSection.addEventListener('transitionend', startApp);
            loadingSection.addEventListener('transitioncancel', startApp);
            loadingSection.classList.add('ready');
        });
    }
    let fullScreenDiv = document.getElementById('full-screen');
    let transitionEndFunction = () => {
        fullScreenDiv.style.display = 'none';
        fullScreenDiv.removeEventListener('transitionend', transitionEndFunction);
        fullScreenDiv.removeEventListener('transitioncancel', transitionEndFunction);
    }
    function loadFullScreen (event) {
        let src = event.currentTarget.getAttribute('src');
        fullScreenDiv.style.backgroundImage = `url("${src}")`;
        fullScreenDiv.style.display = 'block';
        if (!src.includes('full_')) {
            let tmpSrc = 'res/images/full_' + src.replace('res/images/', '');
            let tmpImg = new Image();
            let wait = new Promise((resolve) => {
                tmpImg.addEventListener('load', resolve, {once: true});
            });
            tmpImg.src = tmpSrc;
            wait.then(() => {
                fullScreenDiv.style.backgroundImage = `url("${tmpSrc}")`;
            });
        }
        setTimeout(() => {
            fullScreenDiv.classList.add('open');
            document.body.classList.remove('loaded');
            fullScreenDiv.addEventListener('click', () => {
                document.body.classList.add('loaded');
                fullScreenDiv.classList.remove('open');
                fullScreenDiv.addEventListener('transitionend', transitionEndFunction);
                fullScreenDiv.addEventListener('transitioncancel', transitionEndFunction);
            });
        });
    }
    // In this section all images are going to be loaded:
    setTimeout(() => {
        let images = document.querySelectorAll('img[data-src]');
        let loadingPercentage = 0;
        let allPromises = [];
        let resolveFunction = (event) => {
            event.currentTarget.removeAttribute('data-src');
            loadingPercentage++;
            loadingElement.innerText = Math.floor(loadingPercentage * 75 / (images.length + 4)) + 25; // +4 because portada images
            return event.currentTarget.resolveFunction();
        };
        let loadImage = (img) => {
            return new Promise((resolve) => {
                img.resolveFunction = resolve;
                img.addEventListener('load', resolveFunction);
                img.addEventListener('error', resolveFunction);
                img.setAttribute('src', img.getAttribute('data-src'));
                img.addEventListener('click', loadFullScreen);
            });
        }
        // Load portada images
        for (let i = 1; i < 5; i++) {
            let img = new Image();
            allPromises.push(loadImage(img));
            img.src = `../res/images/slider_josefina_${i}.jpg`;
        }
        [].forEach.call(images, (img) => {
            allPromises.push(loadImage(img));
        });
        Promise.all(allPromises).then(allImagesLoad);
    });
    // Load ok
    setTimeout(() => {
        let fadeElement = document.getElementById('fade');
        fadeElement.classList.remove('show');
        fadeElement.style.zIndex = '99998';
        let transictionEndCallback = () => {
            initJumpTo();
            fadeElement.removeEventListener('transitionend', transictionEndCallback);
            fadeElement.removeEventListener('transitioncancel', transictionEndCallback);
        }
        fadeElement.addEventListener('transitionend', transictionEndCallback);
        fadeElement.addEventListener('transitioncancel', transictionEndCallback);
        window.objectFitImages();
    }, 1500);
    // Window onload
    window.onload = () => {
        // Gpu3d
        let mustLoadGPL = false;
        let webGPL = document.getElementById('webGPL');
        suscribeElementScrollCheck(webGPL, 1, () => {
            if (!mustLoadGPL) {
                mustLoadGPL = true;
                init3D();
            }
        });
        // PDF
        let mustLoadPDF = false;
        let pdf = document.getElementById('zoom-container');
        suscribeElementScrollCheck(pdf, 1, () => {
            if (!mustLoadPDF) {
                mustLoadPDF = true;
                initJquery();
            }
        });
        // CheckScroll
        if (!'IntersectionObserver' in window) {
            checkScrollOfElement();
            window.addEventListener('scroll', throttle(checkScrollOfElement, 60));
        }
    };
}

function onLoadGooey (url) {
    // GooeyEffect init
    let gooeyContainer = document.getElementById('gooey-effect');
    initGooeyEffectShape();
    setTimeout(gooeyEffectPlay, 200);
    suscribeElementScrollCheck(gooeyContainer, 1, () => {
        gooeyEffectPlay();
    }, () => {
        gooeyEffectPause();
    });
}
function init3D () {
    let OBJLoader = THREE.GLTFLoader;
    let container;
    let camera, scene, renderer;
    let mouseX = 0, mouseY = 0;
    let windowHalfX = document.documentElement.clientWidth / 2;
    let windowHalfY = document.documentElement.clientHeight / 2;

    let object;

    init();
    animate();

    function init() {
        container = document.createElement( 'div' );
        document.body.appendChild( container );

        camera = new THREE.PerspectiveCamera( 45, document.documentElement.clientWidth / document.documentElement.clientHeight, 1, 2000 );
        camera.position.z = 250;

        // scene
        scene = new THREE.Scene();
        let ambientLight = new THREE.AmbientLight( 0xcccccc, 1 );
        scene.add( ambientLight );

        let pointLight = new THREE.PointLight( 0xffffff, 1 );
        camera.add( pointLight );
        scene.add( camera );

        // model

        function onProgress( xhr ) {
            /* if ( xhr.lengthComputable ) {
                let percentComplete = xhr.loaded / xhr.total * 100;
                console.log( 'model ' + Math.round( percentComplete, 2 ) + '% downloaded' );
            }*/
        }

        function onError() {
        }

        let loader = new OBJLoader();
        loader.load( '../res/3d/bella_serie_test_imp.gltf', ( obj ) => {
            document.getElementById('loading-3d-model').style.display = 'none';
            object = obj.scene;
            object.position.y = -95;
            scene.add(object);
        }, onProgress, onError );

        container = document.getElementById( 'webGPL' );
        renderer = new THREE.WebGLRenderer({
            alpha: true
        });
        renderer.setPixelRatio( window.devicePixelRatio );
        renderer.setSize( document.documentElement.clientWidth, document.documentElement.clientHeight );
        container.appendChild( renderer.domElement );

        document.addEventListener( 'mousemove', onDocumentMouseMove, false );
        document.addEventListener( 'touchmove', onDocumentTouchMove, false);
        window.addEventListener( 'resize', onWindowResize, false );
    }

    function onWindowResize() {
        windowHalfX = document.documentElement.clientWidth / 2;
        windowHalfY = document.documentElement.clientHeight / 2;
        camera.aspect = document.documentElement.clientWidth / document.documentElement.clientHeight;
        camera.updateProjectionMatrix();
        renderer.setSize( document.documentElement.clientWidth, document.documentElement.clientHeight );
    }

    function onDocumentMouseMove( event ) {
        mouseX = ( event.clientX - windowHalfX ) / 2;
        mouseY = ( event.clientY - windowHalfY ) / 2;
    }

    function onDocumentTouchMove ( event ) {
        mouseX = ( event.touches[0].clientX - windowHalfX ) / 2;
        mouseY = ( event.touches[0].clientY - windowHalfY ) / 2;
    }

    function animate() {
        requestAnimationFrame( animate );
        render();
    }

    function render() {
        camera.position.x += ( mouseX - camera.position.x ) * .05;
        camera.position.y += ( - mouseY - camera.position.y ) * .05;
        camera.lookAt( scene.position );
        renderer.render( scene, camera );
    }
}
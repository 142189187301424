const viewportState = {
    'IN_SCREEN': true,
    'OUT_SCREEN': false
}

let itemsToCheck = [];

function throttle (callback, limit) {
    let waiting = false;
    return () => {
        if (!waiting) {
            waiting = true;
            setTimeout(function () {
                waiting = false;
                callback.apply(this, arguments);
            }, limit);
        }
    }
}

function suscribeElementScrollCheck (element, percentage = 80, enterCallback, outsideCallback, allTime, checkTopZero) {
    if ('IntersectionObserver' in window) {
        percentage = percentage / 100;
        let config = {
            root: null,
            rootMargin: '0px',
            threshold: [0, percentage]
        };
        if (allTime) {
            config.threshold = [0, .05, .1, .15, .2, .25, .3, .35, .4, .45, .5, .55, .6, .65, .7, .75, .8, .85, .9, .95, 1];
        }
        let observer = new IntersectionObserver((entries) => {
            entries.forEach(function (entry) {
                const {isIntersecting, intersectionRatio, intersectionRect} = entry;
                if (checkTopZero && isIntersecting === true) { // maybe this is the correct formula for all.
                    let ratio = 0.9;
                    if (element.offsetHeight > window.innerHeight) {
                        ratio = window.innerHeight / element.offsetHeight;
                    }
                    if (ratio - intersectionRatio < 0.1) {
                        enterCallback(element, true);
                    }
                } else if (!checkTopZero) {
                    if (isIntersecting === true && intersectionRatio >= percentage) {
                        if (enterCallback) {
                            enterCallback(element, true);
                        }
                    // observer.unobserve(entry.target);
                    } else if (isIntersecting === false || intersectionRatio < percentage) {
                        if (outsideCallback) {
                            outsideCallback(element, false);
                        }
                    }
                }
            });
        }, config);
        const domElements = document.querySelectorAll('.my-elems');
        observer.observe(element);
    } else {
        itemsToCheck.push({
            element,
            percentage,
            enterCallback,
            outsideCallback,
            lastState: null,
            allTime
        })
    }
}

function checkScrollOfElement() {
    let viewportHeight = window.innerHeight;
    let viewportOffset = window.pageYOffset;
    itemsToCheck.forEach((item) => {
        let visibilityFraction = item.percentage / 100;
        let visible = Math.max(0, Math.min(item.element.offsetHeight, viewportOffset + viewportHeight - item.element.offsetTop, item.element.offsetTop + item.element.offsetHeight - window.pageYOffset));
        visible = visible / item.element.offsetHeight;
        if (visible > visibilityFraction) {
            if (item.lastState !== viewportState['IN_SCREEN'] || item.allTime) {
                item.lastState = viewportState['IN_SCREEN'];
                if (item.enterCallback) {
                    item.enterCallback(item.element, visible);
                }
            }
        } else {
            if (item.lastState !== viewportState['OUT_SCREEN'] || item.allTime) {
                item.lastState = viewportState['OUT_SCREEN'];
                if (item.outsideCallback) {
                    item.outsideCallback(item.element, visible);
                }
            }
        }
    });
}

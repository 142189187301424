let jumpToButtonAction = (event) => {
    let codeName = event.target.dataset.jump || event.target.innerText.toLowerCase().replace(/ /g, '-').normalize('NFKD').replace (/[\u0300-\u036F]/g, '');
    if (codeName ===  'yann-lorvo') {
        codeName = 'lionel-paradisi-coulouma';
    }
    let element = document.getElementsByClassName(codeName);
    scrollToPos(element[0].offsetTop);
}

let fadeElement = null;
let jumping = false;
let postToJump = 0;

let scrollToPos = (pos) => {
    jumping = true;
    postToJump = pos - (window.innerWidth > 767 ? 70 : 0);
    fadeElement.classList.add('show');
}

let initJumpTo = () => {
    let jumpToButtons = document.getElementsByClassName('jumpToButtonBiblio');
    fadeElement = document.getElementById('fade');
    let tansitionEndCallback = () => {
        if (fadeElement.classList.contains('show')) {
            window.scrollTo({top: postToJump});
            fadeElement.classList.remove('show');
        } else {
            jumping = false;
        }
    };
    fadeElement.addEventListener('transitionend', tansitionEndCallback);
    fadeElement.addEventListener('transitioncancel', tansitionEndCallback);
    for (let button of jumpToButtons) {
        button.addEventListener('click', jumpToButtonAction);
    }
}

class gooeyEffectShape {
    constructor (left, top, {color = null, text, backgroundImage}) {
        this.element = document.createElement('div');
        this.element.className = 'gooey-effect-shape';
        this.setPosition(left, top);
        if (color) {
            this.setColor(color);
        }
        if (text) {
            this.element.textContent = text;
        }
    }
    setPosition (left, top) {
        this.element.style.left = `${left}%`;
        this.element.style.top = `${top}%`;
    }
    setColor(color) {
        this.element.style.color = color;
    }
}

/* Config */
const gooeyEffectConfig = {
    offset: document.documentElement.clientHeight * 0.015,
    interval: 8000,
    nameArrays: ['Matías Errázuriz Ortúzar', 'Josefina', 'Patricio Tejedor', 'Román Vitali', 'Liliana Porter', 'Pablo Reinoso', 'Gladys Nistor' ,'Marie Orensanz' ,'Beatriz Trepat' ,'Pauline Fondevila' ,'Adriana Bustos' ,'Marina De Caro' ,'Marcelo Saraceno' ,'Gonzalo Maciel' ,'Laura Spivak' ,'Carlos Herrera' ,'Nicola Costantino' ,'Gabriel Valansi' ,'Julián Galay' ,'Martín Churba' ,'Miguel Gandolfo' ,'Pompi Gutnisky', 'Antonio Seguí', 'Ingrid Beck', 'Ricardo Watson', 'Hugo Pontoriero', 'Yann Lorvo', 'Lionel Paradisi-Coulouma', 'Martín Marcos', 'Fernando Farina', 'Nicolás Farina'],
    ammount: 0
}
gooeyEffectConfig.ammount = gooeyEffectConfig.nameArrays.length;

/* Variables */
let gooeyEffectShapeArray = [];
let gooeyEffectTimer = null;

function createGooeyEffectShapes () {
    let gooeyEffectContainer = document.getElementById('gooey-effect-container');
    for (let index = 0; index < gooeyEffectConfig.ammount; index++) {
        let tempGooeyEffectShape = new gooeyEffectShape(Math.random() * (100 - gooeyEffectConfig.offset), Math.random() * (100 - gooeyEffectConfig.offset), {text: gooeyEffectConfig.nameArrays.pop()});
        gooeyEffectContainer.appendChild(tempGooeyEffectShape.element);
        gooeyEffectShapeArray.push(tempGooeyEffectShape);
    }
}

function updatePositions () {
    gooeyEffectShapeArray.forEach(gooeyEffectElement => {
        gooeyEffectElement.setPosition(Math.random() * (100 - gooeyEffectConfig.offset), Math.random() * (100 - gooeyEffectConfig.offset));
        // gooeyEffectElement.setPosition(Math.random() * (100 - gooeyEffectConfig.offset) / 2 + 25, Math.random() * (100 - gooeyEffectConfig.offset) / 4 + 23);
    });
}

function initGooeyEffectShape() {
    createGooeyEffectShapes();
}

function gooeyEffectPause() {
    clearTimeout(gooeyEffectTimer);
}

function gooeyEffectPlay () {
    setTimeout(updatePositions);
    gooeyEffectTimer = setInterval(updatePositions, gooeyEffectConfig.interval);
}